module controllers {

  import IUserPopulated             = models.IUserPopulated;
  import IUsersService              = services.IUsersService;
  import IUser                      = models.IUser;
  import ICommercialCalendarService = services.ICommercialCalendarsService;
  import ICommercialCalendar        = models.ICommercialCalendar;
  import IExportsService            = services.IExportsService;
  import IP2RService                = services.IP2RService;
  import IEventsService = services.EventsService;
  import IDisplaysArrayService = services.IDisplaysArrayService;
  import IActivitiesService = services.IActivitiesService;

  export class DashboardArrayLeaderBlockController implements angular.IController {
    $onInit = () => { };

    private $scope: any;
    private $state: ng.ui.IStateService;
    private usersService: IUsersService;
    private commercialCalendarService: ICommercialCalendarService;
    private p2RService: IP2RService;
    public exportsService: IExportsService;
    private eventsService: IEventsService;
    private displaysArrayService: IDisplaysArrayService;
    private activitiesService: IActivitiesService;
    private $q: ng.IQService;
    public user: IUserPopulated;
    public leaderList: IUser[];
    public leaderCount: number;
    public divisionNameList: string[];
    public commercialCalendarList: ICommercialCalendar[];
    public currentCycleForced: ICommercialCalendar;
    public currentQuarterName: string;
    public numberOfItemByPage: number;
    public currentPage: number;
    public numberOfPages: number;
    public orderBySens: number;
    public orderByLabel: string;
    public filter: any;
    public displayFilterDivisionName: string;
    public initLeaderListLength: number;
    private tableHeaders: any;
    public leaderP2RLevelList: string[];

    public static $inject = [
      '$scope',
      '$state',
      'UsersService',
      'CommercialCalendarsService',
      'P2RService',
      '$q',
      'ExportsService',
      'ActivitiesService',
      'EventsService',
      'DisplaysArrayService',
    ];

    /**
     *
     * @param $scope
     * @param $state
     * @param usersService
     * @param commercialCalendarService
     * @param p2RService
     * @param eventsService
     * @param displaysArrayService
     */
    constructor(
        $scope: any,
        $state: ng.ui.IStateService,
        usersService: IUsersService,
        commercialCalendarService: ICommercialCalendarService,
        p2RService: IP2RService,
        $q: ng.IQService,
        exportsService: IExportsService,
        activitiesService: IActivitiesService,
        eventsService: IEventsService,
        displaysArrayService: IDisplaysArrayService) {

      this.$scope = $scope;
      this.$state = $state;
      this.usersService = usersService;
      this.commercialCalendarService = commercialCalendarService;
      this.activitiesService = activitiesService;
      this.p2RService = p2RService;
      this.$q = $q;
      this.exportsService = exportsService;
      this.displayFilterDivisionName = $scope.displayFilterDivisionName;
      this.eventsService = eventsService;
      this.displaysArrayService = displaysArrayService;

      this.$scope.$on(this.eventsService.getEventBy('goto'), function(event, data) {
        this.setPage(data)
      }.bind(this));
      this.$scope.$on(this.eventsService.getEventBy('filter'), function(event, data) {
        this.setOrderBy(data.sort, data.sens)
      }.bind(this));

      this.numberOfItemByPage = 10;
      this.currentPage = 0;
      this.orderBySens = 1;
      this.orderByLabel = 'lastName';
      this.filter = {
        p2RLevel: null,
        divisionName: null,
      };

      this.$q.all(
          [$scope.promiseUserPopulated, $scope.promiseCommercialCalendar]).
          then(function(data: any) {
            this.user = data[0];
            this.commercialCalendarList = data[1];
            this.currentCycleForced = this.commercialCalendarService.getCurrentCycleForced(
                this.commercialCalendarList);
            this.currentQuarterName = this.currentCycleForced.quarterName;

            this.tableHeaders = [
              {label: "SUIVI", key: "follow", sort: false, class: this.displaysArrayService.getClassBy('both')},
              {label: "NOM PRÉNOM", key: "lastName", sort: true, class: this.displaysArrayService.getClassBy('desktop')},
              {label: "NOM", key: "lastNameMob", sort: false, class: this.displaysArrayService.getClassBy('mobile')},

              {label: "PALIER", key: "palier", sort: false, class: this.displaysArrayService.getClassBy('both')},
              {label: "EFFECTIF N1 + N2", key: "numberInTeam", sort: true, class: this.displaysArrayService.getClassBy('desktop')},

              {label: "NB ACTIVE TRIMESTRE (100€ HT)", key: "numberActiveInTeam", sort: true, class: this.displaysArrayService.getClassBy('desktop')},
              {label: "CA PERSO TRIMESTRIEL", key: "caPerso", sort: false, class: this.displaysArrayService.getClassBy('both')},

              // {label: "Période "+this.getMonthNumberRelativeToQuarter(1), key: "cycle"+this.getMonthNumberRelativeToQuarter(1), sort: false, class: this.displaysArrayService.getClassBy('desktop')},
              // {label: "Période "+this.getMonthNumberRelativeToQuarter(2), key: "cycle"+this.getMonthNumberRelativeToQuarter(2), sort: false, class: this.displaysArrayService.getClassBy('desktop')},
              // {label: "Période "+this.getMonthNumberRelativeToQuarter(3), key: "cycle"+this.getMonthNumberRelativeToQuarter(3), sort: false, class: this.displaysArrayService.getClassBy('desktop')},
            ];
            if(this.user.profile == 'DR') {
              this.tableHeaders.push({label: "DIV/ZONE", key: "divisionName", sort: true, class: this.displaysArrayService.getClassBy('desktop')})
            }
            this.initVariable(this.user);

          }.bind(this));

    }

    public filterModification(attributName, attibutValue) {
      this.currentPage = 0;
      this.filter[attributName] = attibutValue;
      this.initVariable(this.user);
    }

    private initVariable(user) {

      if (user.profile == 'DR' || user.profile == 'DD' ||
          user.profile == 'ZM' || user.profile == 'LDS' ||
          user.profile == 'ADS') {
        this.usersService.getLeaderListByGSDRValue(user.GSDRValue,
            this.orderByLabel, this.orderBySens, this.numberOfItemByPage,
            this.currentPage * this.numberOfItemByPage, this.filter.p2RLevel,
            this.filter.divisionName, this.filter.isActive, this.filter.name, this.currentCycleForced.quarterName).then(
            function(data) {
              if (!this.initLeaderListLength) {
                this.initLeaderListLength = data.count;
              }

              this.populateLeaderList(data.list);
              this.leaderCount = data.count;
              this.divisionNameList = data.select;
              this.leaderP2RLevelList = data.p2RLevel;
              this.numberOfPages = Math.ceil(data.count /
                  this.numberOfItemByPage);

              this.$scope.$broadcast(this.eventsService.getEventBy('sort'), {sort: this.orderByLabel, sortSens: this.orderBySens});
              this.$scope.$broadcast(this.eventsService.getEventBy('number'), this.numberOfPages);
              this.$scope.$broadcast(this.eventsService.getEventBy('data'), this.leaderList);
            }.bind(this),
            function(err) {
              console.error(err);
            },
        );
      } else if (user.profile == 'AA' || user.profile == 'Leader') {
        this.usersService.getSubUserByMatricule(user.matricule, user.GSDRValue, this.currentCycleForced.quarterName).
            then(function(subUserListObject) {
              this.leaderList = subUserListObject.uniqueLeader.filter(function(item) {
                let cond = (item.p2RLevel != '' || item.lastLevelLeader != '') && ( item.parentMatricule ==
                    user.matricule || item.grandParentMatricule == user.matricule );
                if(this.filter.name && this.filter.name != '') {
                  cond = cond && item.lastName.search(new RegExp(this.filter.name, "i")) != -1;
                }
                if (this.filter.p2RLevel && this.filter.p2RLevel !== '')
                {
                  cond = cond && (item.p2RLevel === (this.filter.p2RLevel) || item.lastLevelLeader === this.filter.p2RLevel) ;
                }
                if (this.filter.isActive === 'true' || this.filter.isActive === 'false')
                {
                  const isActive = this.filter.isActive === 'true';
                  cond = cond && (item.isActive === isActive);
                }
                return cond;
              }.bind(this));
              this.leaderCount = this.leaderList.length;
              if (!this.initLeaderListLength) {
                this.initLeaderListLength = this.leaderCount || 0;
              }
              this.numberOfPages = Math.ceil(this.leaderCount /
                  this.numberOfItemByPage);
              this.populateLeaderList(this.leaderList);
          const uniqueLeaderList = subUserListObject.uniqueLeader.filter(function(item) {
            return (item.p2RLevel != '' || item.lastLevelLeader != '') && item.parentMatricule ==
                user.matricule;
          });
          const leaderP2RLevelSet = new Set();
          uniqueLeaderList.forEach((leader) => {
            if (leader.p2RLevel !== '') {
              leaderP2RLevelSet.add(leader.p2RLevel);
            }
            if (leader.p2RLevel === '' && leader.lastLevelLeader !== '') {
              leaderP2RLevelSet.add(leader.lastLevelLeader);
            }
          });
          this.leaderP2RLevelList = Array.from(leaderP2RLevelSet);


              this.$scope.$broadcast(this.eventsService.getEventBy('number'), this.numberOfPages);
              this.$scope.$broadcast(this.eventsService.getEventBy('data'), this.leaderList);
        }.bind(this));
      } else {
        console.error('Profile not allowed in array leader', user.profile);
      }
    }

    public populateLeaderList(data) {
      this.leaderList = [];
      data.forEach((e) => {
        let user;

        user = {
          follow: {display: this.displaysArrayService.getDisplayBy('gototxtless'), matricule: e.matricule, class: this.displaysArrayService.getClassBy('both')},
          lastName: {display: this.displaysArrayService.getDisplayBy('showmoreparent'), index: 0, value: e.lastName + " \n" + e.firstName, class: this.displaysArrayService.getClassBy('desktop')},
          lastNameMob: {display: this.displaysArrayService.getDisplayBy('showmoreparent'), index: 0, value: e.lastName + " \n" + e.firstName, class: this.displaysArrayService.getClassBy('mobile')},
          palier: {display: this.displaysArrayService.getDisplayBy('default'), value: "", class: this.displaysArrayService.getClassBy('both')},
          numberInTeam: {display: this.displaysArrayService.getDisplayBy('default'), value: e.numberInTeam, class: this.displaysArrayService.getClassBy('desktop')},
          numberActiveInTeam: {display: this.displaysArrayService.getDisplayBy('default'), value: e.numberActiveInTeam, class: this.displaysArrayService.getClassBy('desktop')},
          caPerso: {display: this.displaysArrayService.getDisplayBy('average'), value: e.turnoverCurrentQuarter, class: this.displaysArrayService.getClassBy('both')},
          userExpand: {0: e},
          expanded: {0: false}
        };
        // user['cycle'+this.getMonthNumberRelativeToQuarter(1)] = {display: this.displaysArrayService.getDisplayBy('default'), value: this.getUserP2RByCycle(e, 1), class: this.displaysArrayService.getClassBy('desktop')};
        // user['cycle'+this.getMonthNumberRelativeToQuarter(2)] = {display: this.displaysArrayService.getDisplayBy('default'), value: this.getUserP2RByCycle(e, 2), class: this.displaysArrayService.getClassBy('desktop')};
        // user['cycle'+this.getMonthNumberRelativeToQuarter(3)] = {display: this.displaysArrayService.getDisplayBy('default'), value: this.getUserP2RByCycle(e, 3), class: this.displaysArrayService.getClassBy('desktop')};

        user.palier.value = e.p2RLevel ? e.p2RLevel : e.lastLevelLeader + " - " + e.lastQuarterLeader;
        if(!e.p2RLevel) {
          user.palier.warning = "#f9d6d6";
        }
        if(this.user.profile === 'DR') {
          user["divisionName"] = {display: this.displaysArrayService.getDisplayBy('default'), value: e.divisionName, class: this.displaysArrayService.getClassBy('desktop')};
        }

        this.leaderList.push(user);
      });
      return this.leaderList
    }

    public filterOnP2RLevel() {
      return function(row) {
        if (this.filter && this.filter.p2RLevel) {
          return row.p2RLevel == this.filter.p2RLevel || row.lastLevelLeader == this.filter.p2RLevel;
        } else {
          return true;
        }
      }.bind(this);
    }

    public filterOnActive() {
      return function(row) {
        if (this.filter && this.filter.isActive) {
          return !row.lastLevelLeader;
        } else if (this.filter && !this.filter.isActive) {
          return row.lastLevelLeader;
        } else {
          return true;
        }
      }.bind(this);
    }

    /**
     * filter not me
     * @param matricule
     * @returns {(row:any)=>boolean}
     */
    public notMe(matricule) {
      return function(row) {
        return row.matricule != matricule;
      };
    }

    public goToDashboard(matricule: string): void {
      var url = this.$state.href('seeDashboard', {matricule: matricule});
      window.open(url,'_blank');
    }

    /**
     *  get the number of coach
     * @param leader
     * @returns {number}
     */
    public getCoachsNumberByUser(leader: IUserPopulated) {
      return this.usersService.getAllSubUserListTeam(leader).length;
    }

    /**
     * get the number of active coach
     * @param leader
     * @returns {string}
     */
    public getActiveCoachsNumberByUser(leader: IUserPopulated) {
      return this.usersService.getNumberActiveAllSubUserListTeam(leader) + '';
    }

    /**
     * Regle metier
     * On prend le trimestre en cours ( c'est à dire le trimestre qui contient le cycle forcé) -> on en déduit les 3 cycles qui sont dedans
     Si on est dans le cycle forcé, on affiche le user.currentP2RLevel
     Si on n'est pas dans le cycle forcé on affiche objectif.maxLevelMonth1P2Rpour la collone J, objectif.maxLevelMonth2P2R pour la collone K, objectif.maxLevelMonth3P2R pour la collone K,
     * @param user
     * @param cycleNumber
     * @returns {string}
     */
    /*public getUserP2RByCycle(user: IUserPopulated, cycleNumber: number) {
      if (user && cycleNumber) {
        var isCurrentCycle = this.commercialCalendarService.getMonthNumberRelativeToQuarter(
            cycleNumber, this.currentQuarterName) ==
            this.commercialCalendarService.getCycleNumber(
                this.currentCycleForced);
        if (isCurrentCycle) {
          return user.objective ? user.objective.currentLevelP2R : '';
        } else {
          switch (cycleNumber) {
            case 1:
              return user.objective ? user.objective.maxLevelMonth1P2R : '';
              break;
            case 2:
              return user.objective ? user.objective.maxLevelMonth2P2R : '';
              break;
            case 3:
              return user.objective ? user.objective.maxLevelMonth3P2R : '';
              break;
            default:
              console.error('cycleNumber is not an allowed values : ' +
                  cycleNumber);

          }
        }
      }
    }*/

    /**
     * to export data in excel
     *
     */
    public exportExcel() {
      this.exportsService.exportLeader(this.user.matricule,
          this.user.GSDRValue);
    }

    public changeP2RLevel() {
      this.currentPage = 0;
      this.initVariable(this.user);
    }

    public changeIsActive() {
      this.currentPage = 0;
      this.initVariable(this.user);
    }

    public caPersoValidate() {
      this.currentPage = 0;
      this.initVariable(this.user);
    }

    public changeDivisionName() {
      this.currentPage = 0;
      this.initVariable(this.user);
    }

    public getMonthNumberRelativeToQuarter(offset) {
      if (this.currentQuarterName) {
        return this.commercialCalendarService.getMonthNumberRelativeToQuarter(
            offset, this.currentQuarterName);
      }
    }

    public setOrderBy(sort, sortSens) {
      this.orderBySens = sortSens;
      this.orderByLabel = sort;
      this.leaderList = this.leaderList.sort((a: any,b: any) => {
        if(this.orderBySens === 1){
          return a[sort].value >= b[sort].value ? 1:-1;
        } else {
          return b[sort].value >= a[sort].value ? 1:-1;
        }
      });
      this.$scope.$broadcast(this.eventsService.getEventBy('sort'), {sort, sortSens});
    }

    public previousPage() {
      if (this.currentPage > 0) {
        this.currentPage--;
        this.initVariable(this.user);
      }
    }

    public nextPage() {
      if (this.currentPage < this.numberOfPages - 1) {
        this.currentPage++;
        this.initVariable(this.user);
      }
    }

    public setPage(index: number) {
      this.currentPage = index;
      this.initVariable(this.user);
    }

    /**
     * Get number
     *
     * @param num
     * @return {any[]}
     */
    public getNumber = function(num: number): any[] {
      return new Array(num);
    };

  }
}

appDashboard.controller('DashboardArrayLeaderBlockController',
    controllers.DashboardArrayLeaderBlockController);
